export interface ICustomerModel {
    customer_id: number;
    partner_code: string;
    full_name: string;
    email: string;
    phone: string;
    image?: string;
    salt?: string;
    password?: string;
    type?: "root" | "admin" | "readonly";
    status: "active" | "inactive";
    last_login?: Date;
    created_at: Date;
};

export const defaultCustomerValue: ICustomerModel = {
    customer_id: 0,
    partner_code: "",
    full_name: "",
    phone: "",
    email: "",
    created_at: new Date(),
    status: "inactive"
}

export interface IPagination {
    perPage?: number;
    currentPage?: number;
    lastPage?: number;
    from?: number;
    to?: number;
    total?: number;
}

export interface IOrderModel {
    order_id: number;
    partner_code: string;
    order_date: string;
    description: string;
    customer_id: number;
    service_group_code: string;
    invoice_number: string;
    reference_number: string;
    id_order_kh: string;
    id_invoice_kh: string;
    invoice_number_kh:string;
    discount_id: number;
    sub_total: number;
    discount: number;
    total: number;
    order_status: "draft" | "incomplete-data" | "unpaid" | "paid" | "failed" | "rejected" | "fraud";
    order_token: string;
    updated_at: string;
    created_by_admin: number;
    status_email: "sent_complete_data" | "sent_unpaid_1" | "sent_unpaid_3" | "sent_paid";
    json_data: any;
    deleted_at: string;
    deleted_reason: string;
    deleted_by: number;
};

export const defaultOrderValue: IOrderModel = {
    order_id: 0,
    partner_code: "",
    order_date: "",
    description: "",
    customer_id: 0,
    service_group_code: "",
    invoice_number: "",
    reference_number: "",
    id_order_kh: "",
    id_invoice_kh:"",
    invoice_number_kh:"",
    discount_id: 0,
    sub_total: 0,
    discount: 0,
    total: 0,
    order_status: "draft",
    order_token: "",
    updated_at: "",
    created_by_admin: 1,
    status_email: "sent_complete_data",
    json_data: "",
    deleted_at: "",
    deleted_reason: "",
    deleted_by: 0,
}

export interface IPartnerServiceModel {
    id: number;
    partner_code: string;
    service_group_code: string;
    service_id: number;
    name: string;
    price: number;
    margin_type: "percentage" | "nominal";
    margin_value: number;
    show_price_landing_page: number;
    deleted_at?: string;
    checked: number;
}

export interface IPartnerModel {
    partner_code: string;
    name: string;
    domain: string;
    partner_short_name: string;
    partner_long_name: string;
    independent: boolean;
    show_client_testimony: boolean;
    partner_website?: string;
    inject_script?: string;
    logo_square_url: string;
    logo_square_file?: any;
    logo_square_filename?: string;
    logo_rectangular_url: string;
    logo_rectangular_file?: any;
    logo_rectangular_filename?: string;
    status: "active" | "inactive";
    config_json: {
        about: string;
        address: string;
        terms_conditions_url: string;
        privacy_policy_url: string;
        twitter_id: string;
        instagram_id: string;
        facebook_id: string;
        linkedin_id: string;
        service_group: Array<string>;
        bcc_emails: Array<string>;
        sender_email_as: string;
        favicon_url?: string;
        favicon_file?: any;
        favicon_filename?: string;
        yt_video_id?: string;
        // logo?: string;
        // logo_file?: File;
        // logo_file_url?: string;
    };
    partner_service: Array<IPartnerServiceModel>;
};

export const defaultPartnerValue: IPartnerModel = {
    partner_code: "",
    name: "",
    domain: "",
    partner_short_name: "",
    partner_long_name: "",
    independent: false,
    show_client_testimony: true,
    partner_website: "",
    logo_square_url: "",
    logo_rectangular_url: "",
    status: "active",
    config_json: {
        about: "",
        address: "",
        terms_conditions_url: "",
        privacy_policy_url: "",
        twitter_id: "",
        instagram_id: "",
        facebook_id: "",
        linkedin_id: "",
        service_group: [],
        bcc_emails: [],
        sender_email_as: "",
        yt_video_id: ""
    },
    partner_service: []
}

export interface IServiceGroupModel {
    service_group_code: string;
    service_group_name: string;
    service_group_short_name: string;
    icon_group: string;
};

export interface IServiceModel {
    service_id: number;
    service_code: string;
    service_type: "main" | "additional";
    name: string;
    description: string;
    base_price: number;
    image: string;
    highlight: number;
    service_group_code: string;
};

export const defaultServiceValue: IServiceModel = {
    service_id: 0,
    service_code: "",
    service_type: "main",
    name: "",
    description: "",
    base_price: 0,
    image: "",
    highlight: 0,
    service_group_code: ""
}

export interface IUserModel {
    user_id: number;
    user_name: string;
    email: string;
    phone: string;
    image?: string;
    salt?: string;
    password?: string;
    type?: "root" | "admin" | "readonly";
    status: "active" | "inactive";
    last_login?: Date;
    created_at?: Date;
    user_partner?: Array<any>;
};

export const defaultUserValue: IUserModel = {
    user_id: 0,
    user_name: "",
    email: "",
    phone: "",
    image: "",
    salt: "",
    password: "",
    type: "admin",
    status: "inactive",
    user_partner: [],
}

export interface IDiscountModel {
    discount_id: number;
    partner_code: string;
    discount_code: string;
    discount_name: string;
    discount_type: "percentage" | "nominal";
    discount_value: number;
    status: "active" | "inactive";
    created_at?: Date;
    created_by?: number;
};

export const defaultDiscountValue = {
    discount_id: 0,
    partner_code: "",
    discount_code: "",
    discount_name: "",
    discount_type: "nominal",
    discount_value: 0,
    status: "inactive"
}