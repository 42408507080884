import React, { useState } from "react";
import SearchBox from "src/components/SearchBox";
import { Table, Card, Row, Col } from "antd";
import { ApiGet } from "src/util/ApiClient";
import { formatDateTime, formatRupiah } from "src/util/Formater";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useApiErrorAlert } from "src/hooks/user";
import { IOrderModel, IPagination } from "src/type-interface/model";
import { CustomerName, PartnerName, ServiceGroupName } from "src/hooks/order";
import { PartnerSelect, ServiceGroupSelect, StatusOrderMap, StatusOrdeSelect } from "src/components/Shared/SelectComponents";
import { history } from "src/appRedux/store";

interface IFilter {
  keywords: string;
  partner_code: string;
  service_group_code: string;
  order_status: string;
}

// const DiskonName: React.FC<{ id: number }> = ({ id }) => {
//   const { listDiskon } = useSelector(({ masterData }: { masterData: any }) => masterData);
//   const status = listDiskon && listDiskon.find((o: any) => Number(o.id) === id);
//   return <span>{status && status.diskon_name}</span>;
// }

const columns = [
  {
    title: "PARTNER",
    dataIndex: "partner_code",
    key: "partner_code",
    width: 175,
    render: (val: any, row: IOrderModel) => <Link to={`/order/${row.order_id}`}><PartnerName partner_code={val} /></Link>,
  },
  {
    title: "ORDERED AT",
    dataIndex: "order_date",
    key: "order_date",
    width: 180,
    render: (val: any) => <span>{formatDateTime(val)}</span>,
  },
  {
    title: "NOMOR ORDER",
    dataIndex: "invoice_number",
    key: "invoice_number",
    width: 175,
    render: (val: any, row: IOrderModel) => <Link to={`/order/${row.order_id}`}>{val}</Link>,
  },
  {
    title: "NAMA LENGKAP",
    dataIndex: "customer_id",
    key: "customer_id",
    width: 220,
    render: (val: number) => <CustomerName id={val} />,
  },
  {
    title: "GRUP LAYANAN",
    dataIndex: "service_group_code",
    key: "service_group_code",
    width: 175,
    render: (val: string) => <ServiceGroupName service_group_code={val} />
  },
  {
    title: "LAYANAN",
    dataIndex: "description",
    key: "description",
    width: 250,
  },
  {
    title: "SUB TOTAL",
    dataIndex: "sub_total",
    key: "sub_total",
    width: 135,
    render: (val: any) => <span>{formatRupiah(Number(val))}</span>,
  },
  {
    title: "DISKON",
    dataIndex: "discount",
    key: "discount",
    width: 135,
    render: (val: any) => <span>{formatRupiah(Number(val))}</span>,
    // render: (val: number) => <DiskonName id={val} />,
  },
  {
    title: "TOTAL",
    dataIndex: "total",
    key: "total",
    width: 135,
    render: (val: any) => <span>{formatRupiah(Number(val))}</span>,
  },
  {
    title: "STATUS",
    dataIndex: "order_status",
    key: "order_status",
    width: 175,
    render: (val: string) => <span>{StatusOrderMap[val]}</span>,
  },
  {
    title: "NOMOR INVOICE KH",
    dataIndex: "invoice_number_kh",
    key: "invoice_number_kh",
    width: 175,
    render: (val: any, row: IOrderModel) => <Link to={`https://khos.kontrakhukum.com/admin/invoices/invoice/${row.id_invoice_kh}`}>{val}</Link>,
  }

];

const OrderListPage: React.FC<any> = (props) => {

  const [orderList, setOrderList] = useState<Array<IOrderModel>>([]);
  const [filter, setFilter] = React.useState<IFilter>({ partner_code: "", service_group_code: "", order_status: "", keywords: "" });
  const [pagination, setPagination] = React.useState<IPagination>({});
  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrorAlert = useApiErrorAlert();
  const onChangeFilter = React.useCallback((key: string, value: string) => {
    const newFilter = { ...filter, [key]: value };
    loadOrderList(getQueryString(undefined, newFilter));
  }, [filter, pagination]);

  const getQueryParams = React.useCallback((query?: string) => {
    const queryParams = new URLSearchParams(query || location.search);
    let params: any = {};
    queryParams.forEach((v, k) => params[k] = v);
    return params;
  }, [location]);

  const getQueryString = React.useCallback((newPagination?: IPagination, newFilter?: any) => {
    return ("?" + new URLSearchParams({
      ...(newPagination || pagination),
      ...(newFilter || filter)
    }).toString());
  }, [filter, pagination]);

  const loadOrderList = React.useCallback(async (query: string) => {
    const res = await ApiGet(`/order/list${query || ""}`);
    if (res.data && Array.isArray(res.data.data)) {
      setOrderList([...res.data.data]);
      const resPagination = res.data.pagination;
      const newQuery = getQueryParams(query);
      const newFilter = {
        ...filter,
        partner_code: newQuery.partner_code || "",
        service_group_code: newQuery.service_group_code || "",
        order_status: newQuery.order_status || "",
        keywords: newQuery.keywords || ""
      };
      const newPagination = { perPage: resPagination.perPage, currentPage: resPagination.currentPage, total: resPagination.total };
      const url = getQueryString(newPagination, newFilter);
      setPagination(newPagination);
      setFilter(newFilter);
      history.replace(`/order${url}`, null);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [orderList, filter, pagination]);

  React.useEffect(() => {
    loadOrderList(location.search);
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Transaksi",
      },
      {
        path: "/order",
        breadcrumbName: "Daftar Pesanan",
      }
    ]));
  }, [location.search]);

  return (
    <Row style={{ rowGap: 0 }}>
      <Col className="ant-col-24">
        <Card title={
          <div style={{ display: "flex", flexDirection: "row", paddingTop: 12 }}>
            <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
              <label style={{ height: 32 }}>Mitra</label>
              <PartnerSelect value={filter.partner_code} onChange={(val: string) => onChangeFilter("partner_code", val)} addAllOption={authUser?.type === "root"} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
              <label style={{ height: 32 }}>Grup Layanan</label>
              <ServiceGroupSelect value={filter.service_group_code} onChange={(val: string) => onChangeFilter("service_group_code", val)} addAllOption={true}/>
            </div>
            {/* <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
              <label style={{ height: 32 }}>Tipe Layanan</label>
              <ServiceTypeSelect value={filter.partner_code} onChange={(val: string) => onChangeFilter("partner_code", val)} addAllOption={true} />
            </div> */}
            <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
              <label style={{ height: 32 }}>Status</label>
              <StatusOrdeSelect value={filter.order_status} onChange={(val) => onChangeFilter("order_status", val)} addAllOption={true} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
              <label style={{ height: 32 }}>Pencarian</label>
              <SearchBox
                placeholder="Kata Kunci..."
                onChange={(e: any) => onChangeFilter("keywords", e.target.value)}
                value={filter.keywords} />
            </div>
          </div>
        }>
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={orderList}
            pagination={{
              pageSize: Number(pagination.perPage),
              total: Number(pagination.total),
              defaultCurrent: 0,
              defaultPageSize: 10,
              current: Number(pagination.currentPage),
              showSizeChanger: true
            }}
            tableLayout="auto"
            scroll={{ y: 425, x: "max-content" }}
            rowClassName={(row) => `row-order-status-${row.order_status}`}
            onChange={(valPagination: any, filter) => {
              const newPagination = {
                perPage: Number(valPagination.pageSize),
                currentPage: Number(valPagination.current)
              };
              navigate(`/order${getQueryString(newPagination)}`);
            }}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  navigate(`/order/${record.order_id}`, { state: { from: location.pathname + location.search } });
                }
              }
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default OrderListPage;